import React from 'react'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  webpSrc: string
  fallbackSrc: string
  alt: string
  className?: string
}

export const Image: React.FC<ImageProps> = ({ webpSrc, fallbackSrc, alt, className, ...props }) => {
  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <img src={fallbackSrc} alt={alt} className={className} {...props} />
    </picture>
  )
}
