import { useMemoizedFn } from 'ahooks'
import { toast, ExternalToast } from 'sonner'

import { useIsMobile } from './useMediaQuery'

export const useErrorToast = () => {
  const isMobile = useIsMobile()

  const toastError = useMemoizedFn((message: string, options?: ExternalToast) => {
    toast.error(message, {
      position: isMobile ? 'bottom-center' : 'bottom-right',
      style: {
        background: '#BC2828',
        color: '#fff',
        border: 'none',
        fontWeight: 500,
        marginBottom: '88px',
      },
      ...options,
    })
  })

  return { toastError }
}

export const useSuccessToast = () => {
  const isMobile = useIsMobile()

  const toastSuccess = useMemoizedFn((message: string, options?: ExternalToast) => {
    toast.success(message, {
      position: isMobile ? 'bottom-center' : 'bottom-right',
      style: {
        background: 'rgba(255, 194, 76, 0.20)',
        color: '#fff',
        border: 'none',
        fontWeight: 500,
        marginBottom: '88px',
      },
      ...options,
    })
  })

  return { toastSuccess }
}
